$mobile-width: 560px;
$tablet-width: 1020px;

@mixin mobile {
	@media (max-width: $mobile-width) {
		@content;
	}
}
@mixin tablet {
	@media (max-width: $tablet-width) and (min-width: $mobile-width + 1){
		@content;
	}
}
@mixin desktop {
	@media (min-width: $tablet-width + 1) {
		@content;
	}
}

