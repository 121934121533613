@import "../../styles/mixin.scss";

.root {
  display: flex;
  height: 100%;
  padding-left: 560px;
  @include tablet {
    padding-left: 20px;
  }
  @include mobile {
    padding-left: 0;
  }
}
.left {
  width: 560px;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--left-panel-background);
  transition: all 0.5s;
  box-shadow: 19px 0px 4px -17px rgba(43,44,56,0.22);
  padding-top: 20px;

  @include tablet {
    &.close {
      margin-left: -540px;
    }
  }
  @include mobile {
    width: 100%;
    padding-top: 12px;
    &.close {
      margin-left: -100%;
    }
  }

}
.btnOpenLeftPanel {
  // border: 1px var(--left-panel-border) solid;
  border-left: none;
  position: absolute;
  left: 100%;
  margin-left: -50px ;
  top: 4px;
  z-index: 3;
  border-radius: 50px;
  padding: 12px 18px;
  width: 100px;

  display: none;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.5s all;

  @include mobile {
    display: flex;
  }
  @include tablet {
    display: flex;
  }
  :global(.fa) {
    transition: 0.5s all;
  }
  :global(.fa-search), :global(.search) {
    opacity: 0;
  }
  background-color: transparent;
  color: rgb(236, 239, 255);
  &.close {
    background-color:var(--left-panel-border);
    // color: #000;

    width: 150px;

    margin-left: -40px ;

    :global(.fa-times) {
      opacity: 0;
    }
    :global(.fa-search), :global(.search) {
      opacity: 1;
    }
  }
  // box-shadow: inset 19px 0px 4px -17px rgba(43,44,56,0.22);
  // @include mobile {
  //   left: auto;
  //   right: 0;
  //   box-shadow: none;

  //   border-top-left-radius: 50px;
  //   border-bottom-left-radius: 50px;


  // }
}
.right {
  flex: 1;
  overflow: scroll;
  overflow: overlay;
}
.searchForm {
  width: 100%;
  padding: 16px 40px 8px;
  >div {
    padding: 4px 0;
  }
}

.loginUser {
  padding: 0 40px 0;
  color: #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    color: #fff;
    text-decoration: underline;
  }
  @include mobile {
    padding: 0 12px 0;
  }
}
.searchResult {
  width: 100%;
  padding: 0 0 40px;
  overflow: scroll;
  overflow: overlay;
  flex: 1;
  @include mobile {
    padding: 0 0 40px;
  }
}
