

.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.tag {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: rgb(236, 239, 255);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  i {
    font-size: 10px;
    display: inline-block;
  }
  span {
    flex: 1;
    display: inline-block;
    padding: 0 4px;
    border-right: 1px var(--left-panel-border) solid;
  }
  button {
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgb(224, 228, 255);
    }
  }
}
