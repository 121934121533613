@import "../../styles/mixin.scss";

$color: #ccc;
.caseRoot {
  position: relative;
  padding: 20px 32px;
  padding-bottom: 100px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .left {
    width: 100%;
  }
}
.secondHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  .left {
    flex: 1;
  }
  .right {
    width: 200px;
    // min-width: 200px;
    text-align: right;
    // @include mobile {
    //   width: 50%;
    //   min-width: 0;
    // }
  }
}
.title {
  margin-top: 24px;
}
.tags {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  .tag {
    display: inline-block;
    text-align: left;
    margin-right: 10px;
    color: var(--tag);
    &:hover {
      text-decoration: underline;
    }
  }
}

.description {
  margin-top: 8px;
}

.status {
  span {
    padding: 2px 0;
    display: block;
  }
}

.keyDate {
  margin-top: 20px;
  small {
    padding: 2px 0;
    display: block;
  }
}

.charge {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  span {
    flex: 1;
  }
  span.confession {
    flex: 0 0 60px;
  }
  .green {
    color: var(--green);
  }
  .red {
    color: var(--red);
  }
  .blue {
    color: var(--blue);
  }
  .yellow {
    color: var(--yellow);
  }

}
.committee {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.avatarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.internalRemarks {
  border: 1px var(--blue) dashed;
  padding: 0 20px;
}

.chargeVerdict {
  padding: 0 5px;
  min-width: 90px;
}
