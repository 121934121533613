.root {
  margin: 24px 0;
  position: relative;
  .bgButton {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:hover .bgButton {
    border: 1px dashed #ccc;
  }
  &.noLabel {
    margin: 0;
  }
  &.noMargin {
    margin: 0;
  }
  &.noPadding {
    .children {
      padding-left: 0;
    }
  }
  input, textarea {
    border: 1px #ddd solid;
    width: 100%;
    padding: 4px;
  }
  textarea {
    min-height: 200px;
  }
}
.label {
  margin: 12px 0;
  color: #444;
  position: relative;
  font-weight: 500;
  &::before {
    content: "";
    position: absolute;
    left: -3px;
    height: 10px;
    width: 10px;
    top: 4px;
    background-color: #c3ffcf;
    z-index: -1;
  }
}
.children {
  padding-left: 20px;
  p {
    line-height: 1.3;
    margin-bottom: 20px;
  }
}
