@import "../../styles/mixin.scss";

.root {
  padding: 50px;
  @include mobile {
    padding: 50px 20px;

  }
  sup {
    vertical-align: super;
    font-size: 10px;
  }
  h1 {
    text-align: center;
    font-size: 1rem;
    margin: 24px 0;
    margin-top: 50px;
    font-weight: 900;
  }
  p {
    margin: 24px 0;
    line-height: 1.6;
  }
}
